/* eslint-disable @nx/enforce-module-boundaries */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isAfterPresentTime, meetingsByDate, truncateStringbyWords } from 'v4/utils/utils';
import {
  getEventDetail,
  getGoogleMeetingDetails,
} from 'v4/store/actions/meeting.actions';
import {
  Button,
  Card,
  HStack,
  Stack,
  Text,
  Spinner,
  Box,
  VStack,
} from '@mybridge/ui';
import { MEETING_LOCATION } from 'v4/lib/data';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styles from '../calendarSnip.module.scss';
import SectionLoader from 'v4/components/common/loader/sectionLoader';

const UpcomingMeetings = ({isDashboard, props}) => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { loading, allCalendarMeeting, allGoogleMeetings, allOutlookMeetings } =
    useSelector((state) => state.calendar);
  const [sortedMeetings, setSortedMeetings] = useState([]);

  const dispatch = useDispatch();
  const router = useRouter();
  const handleEventClick = (event) => {
    if (event?.location_url) {
      const gmeetDetails = {
        id: event?.id,
        title: event?.subject,
        extendedProps: {
          host: event?.host,
          attendees: event?.attendees,
          description: event?.descripion,
          location: null,
          _instance: {
            range: {
              start: event?.start_time?._d,
              end: event?.end_time?._d,
            },
          },
        },
      };
      dispatch(getGoogleMeetingDetails(gmeetDetails));
      router.push('/profile/calendar/viewGoogleMeeting');
      return;
    }
    dispatch(getEventDetail(event?.id)).then((res) => {
      router.push('/profile/calendar/viewMeeting');
    });
  };

  const MeetingDetailing = (day, events) => {
    return (
      <>
        <Box mb={3}>
          <Text fontSize="md" color="#5B5B5B" fontWeight="600">
            {day}
          </Text>
        </Box>
        {!events?.length ? (
          <Text color="brandPrimary.500">No Upcoming Events.</Text>
        ) : (
          events?.map((event, i) =>
            event ? (
              <>
                <HStack gap={3} key={i} display="inline-flex" mb={3}>
                  <Box
                    w="90px"
                    fontSize="15px"
                    fontWeight="medium"
                    color="#5B5B5B"
                  >
                    {moment(event.start_time).format('hh:mm A')}
                  </Box>
                  <VStack gap="3px" alignItems="flex-start" flex="1">
                    <Box
                      fontSize="15px"
                      fontWeight="normal"
                      color="#5B5B5B"
                      as="span"
                      onClick={() => handleEventClick(event)}
                      className={styles.meetingCardSubject}
                      cursor="pointer"
                    >
                      {truncateStringbyWords(event.subject, 2)}
                    </Box>
                    <Box
                      fontSize="14px"
                      fontWeight="normal"
                      as="span"
                      className={styles.meetingsLinksVisit}
                    >
                      {event?.location === 'builtin' ? (
                        <>
                          {
                            MEETING_LOCATION.find(
                              (item) => item.value === event?.location
                            )?.label
                          }
                        </>
                      ) : (
                        <>{event?.location_url}</>
                      )}
                    </Box>
                  </VStack>
                </HStack>
              </>
            ) : (
              <></>
            )
          )
        )}
      </>
    );
  };

  useEffect(() => {
    setSortedMeetings([]);
    let myBridgeMeetings, googleMeetings, outlookMeetings;
    if (allCalendarMeeting.results) {
      myBridgeMeetings = allCalendarMeeting?.results?.filter((event) => {
        const local = moment.utc(event?.start_time).local().format();
        // console.log('check currenttime',isAfterPresentTime(local));
        const declinedAttendee = event?.attendees?.find(
          (m) => m?.user === userProfileInfo?.id && m?.status === 'DECLINED'
        );
        if (
          declinedAttendee?.status !== 'DECLINED' &&
          isAfterPresentTime(local)
        ) {
          if (isAfterPresentTime(local)) return event;
        }
      });
    }
    if (allGoogleMeetings.items) {
      googleMeetings = allGoogleMeetings?.items
        ?.filter((event) => {
          if (
            event?.start?.dateTime &&
            isAfterPresentTime(event?.start?.dateTime) &&
            event?.summary
          )
            return event;
        })
        ?.map((item) => {
          const meetExist = myBridgeMeetings?.filter(
            (meeting) => meeting?.id == item?.id?.slice(6)
          );
          if (meetExist?.length === 0) {
            return {
              attendees: item?.attendees,
              host: item?.organizer?.email,
              description: item?.description,
              start_time: moment(item?.start?.dateTime),
              end_time: moment(item?.end?.dateTime),
              id: item?.id,
              subject: item?.summary,
              location: item?.location ?? 'google',
              location_url: item?.hangoutLink ?? item?.location,
              eventUrl: item?.htmlLink,
            };
          }
        });
    }
    if (allOutlookMeetings.value) {
      outlookMeetings = allOutlookMeetings?.value
        ?.filter((event) => {
          if (
            event?.start?.dateTime &&
            isAfterPresentTime(event?.start?.dateTime) &&
            event?.summary
          )
            return event;
        })
        ?.map((item) => {
          if (!myBridgeMeetings?.includes(item?.id?.slice(6))) {
            return {
              attendees: item?.attendees?.map(
                (attendee) => attendee?.emailAddress?.address
              ),
              host: item?.organizer?.emailAddress?.address,
              description: item?.bodyPreview,
              start_time: moment(item?.start?.dateTime),
              end_time: moment(item?.end?.dateTime),
              id: item?.id,
              subject: item?.subject,
              location: item?.location?.displayName ?? 'outlook',
              location_url:
                item?.onlineMeeting?.joinUrl ?? item?.location?.locationType,
            };
          }
        });
    }

    const sortedMeetings = meetingsByDate(
      [myBridgeMeetings, googleMeetings, outlookMeetings]
        ?.flat()
        ?.filter((a) => a !== undefined)
        ?.sort((a, b) => {
          return new Date(a.start_time) - new Date(b.start_time);
        })
        ?.slice(0, 3)
    );
    setSortedMeetings(sortedMeetings);
  }, [allGoogleMeetings, allCalendarMeeting, userProfileInfo?.id]);

  return (
    <Box as={!isDashboard && Card} variant="thinBorderedCard" {...props}>
      {loading ? (
        <SectionLoader />
      ) : Object.keys(sortedMeetings).length ? (
        <Box className={styles.upcomingMeetBox}>
          <Link className={styles.viewAllLink} href="/profile/calendar/calendar-listing">
            View All
          </Link>
          <Box>
            {sortedMeetings['Today']?.length ? (
              <Box className={styles.upcomingMeetingCard}>
                {MeetingDetailing('Today', sortedMeetings['Today'])}
              </Box>
            ) : (
              ''
            )}
            {sortedMeetings['Tomorrow']?.length ? (
              <Box className={styles.upcomingMeetingCard}>
                {MeetingDetailing('Tomorrow', sortedMeetings['Tomorrow'])}
              </Box>
            ) : (
              ''
            )}
            {Object.keys(sortedMeetings)?.map(
              (meeting) =>
                meeting &&
                meeting !== 'Today' &&
                meeting !== 'Tomorrow' && (
                  // eslint-disable-next-line react/jsx-key
                  <Box className={styles.upcomingMeetingCard}>
                    {MeetingDetailing(meeting, sortedMeetings[meeting])}
                  </Box>
                )
            )}
          </Box>
        </Box>
      ) : (
        <Box p={4}>
          <Text color="#5B5B5B">No Upcoming Meetings</Text>
        </Box>
      )}
    </Box>
  );
};

export default UpcomingMeetings;
